// @mui material components
/* eslint-disable */
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setDirection,
} from "context";
import { useEffect, useState } from "react";
import OrdersService from "services/OrdersService";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { TextField, MenuItem } from "@mui/material";
import { ExportToCsv } from "export-to-csv";
import _ from "lodash";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [del, setDelete] = React.useState(0);
  const [id, setId] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [openStatus, setOpenStatus] = React.useState(false);
  const [changeStatusMessage, setChangeStatusMessage] = React.useState("");
  const [show, setShow] = useState(false);
  const [createNew, setCreate] = useState(false);
  const [_order, setOrder] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [rows, setFiltered] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    direction,
  } = controller;
  const backendUrl = "https://chelloshal-deutschland.de";

  // ------------------- //

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: selectedColumns.map((c) => c.title),
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = () => {
    setShowExportColumns(!showExportColumns);
  };
  const generateExcel = () => {
    let data = rows
      .filter(f => f.field != "actions")
      .map(order => {
        let object = {};
        columns.forEach((item, index) => {
          if (item.field === "id") _.set(object, `id`, `${order.id}`);
          else if (item.field === "customer")
            _.set(object, `customer`, `${order.user?.name || ""}`);
          else if (item.field === "status") _.set(object, `status`, `${order.order_status?.name}`);
          else if (item.field === "address") _.set(object, `address`, `${order?.address || ""}`);
          else if (item.field === "product_count")
            _.set(object, `product_count`, `${order.items?.length || 0}`);
          else _.set(object, item.field, _.get(order, item.field) ?? "");
        });
        return object;
      });
    console.log("data", data);
    csvExporter.generateCsv(data);
  };
  // ------------------- //

  const pColumns = [
    {
      Header: direction == "ltr" ? "Id" : "المعرّف",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.id}
          </MDTypography>
        );
      },
      width: 20,
      dataKey: "id",
    },
    {
      Header: direction == "ltr" ? "Name" : "الاسم",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.product.en_name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "user",
    },
    {
      Header: direction == "ltr" ? "English Name" : "الاسم بالانكليزية",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.product.en_name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "en_name",
    },
    {
      Header: direction == "ltr" ? "Arabic Name" : "الاسم العربية",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.product.ar_name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "ar_name",
    },
    {
      Header: direction == "ltr" ? "Deutch Name" : "الاسم بالألمانية",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.product.de_name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "de_name",
    },
    {
      Header: direction == "ltr" ? "Price" : "السعر",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.product.selling_price}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "selling_price",
    },
    {
      Header: direction == "ltr" ? "Color" : "اللون",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            <p style={{ width: "1em", height: "1em", backgroundColor: d.color }}></p>
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "color",
    },
    {
      Header: direction == "ltr" ? "Quatity" : "الكمية",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.count}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "count",
    },
    {
      Header: direction == "ltr" ? "Total" : "المجموع",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.total}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "total",
    },
  ];
  const columns = [
    {
      Header: direction == "ltr" ? "Id" : "المعرّف",
      field: "id",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.id}
          </MDTypography>
        );
      },
      width: 20,
      dataKey: "id",
    },
    {
      Header: direction == "ltr" ? "Customer Name" : "اسم العميل",
      field: "customer",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.user?.name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "user",
    },
    {
      Header: direction == "ltr" ? "Status" : "حالة الطلب",
      field: "status",
      accessor: d => {
        return (
          <MDTypography
            style={{ color: d.order_status.color }}
            display='block'
            variant='button'
            fontWeight='medium'
          >
            {d.order_status?.name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "order_status",
    },
    {
      Header: direction == "ltr" ? "Address" : "العنوان",
      field: "address",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.address}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "address",
    },
    {
      Header: direction == "ltr" ? "Sub Total" : "المجموع الجزئي",
      field: "sub_total",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.sub_total}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "sub_total",
    },
    {
      Header: direction == "ltr" ? "Total" : "المجموع الكلي",
      field: "total",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.total}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "total",
    },
    {
      Header: direction == "ltr" ? "Products Count" : "عدد العناصر",
      field: "product_count",
      accessor: d => {
        return (
          <MDTypography display='block' variant='button' fontWeight='medium'>
            {d.items?.length}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "brand",
    },
    {
      Header: direction == "ltr" ? "Actions" : "إعدادات",
      field: "actions",
      accessor: d => {
        return (
          <>
            {" "}
            <MDButton
              onClick={() => handleChangeStatus(d.id)}
              style={{ margin: "0 5px 0 5px", backgroundColor: "lightblue" }}
            >
              {direction == "rtl" ? "تعديل حالة الطلب" : "Edit Status"}
            </MDButton>
            <MDButton
              onClick={() => showOrder(d)}
              style={{ margin: "0 5px 0 5px", backgroundColor: "lightblue" }}
            >
              {direction == "rtl" ? "تفاصيل" : "Details"}
            </MDButton>
            <MDButton
              onClick={() => handleClickOpen(1, d.id)}
              color='warning'
              style={{ backgroundColor: "red" }}
            >
              {direction == "rtl" ? "حذف" : "Delete"}
            </MDButton>{" "}
          </>
        );
      },
      width: 50,
    },
  ];

  const getOrders = () => {
    OrdersService.getAllOrders().then(resp => {
      console.log(resp);
      setFiltered(resp.data);
      setOrders(resp.data);
    });
  };
  useEffect(() => {
    getOrders();
  }, []);
  // Damage Dialog

  const ORDER_STATUS = [
    { name: "Pending", status: "pending", serial: 1 },
    { name: "Preparation", status: "preparation", serial: 2 },
    { name: "Delivery", status: "delivery", serial: 3 },
    { name: "Delivered", status: "delivered", serial: 4 },
    { name: "Rejected", status: "rejected", serial: 5 },
  ];
  const [status, setStatus] = React.useState(ORDER_STATUS[0]);
  const handleClickOpen = (number, id) => {
    setDelete(number);
    setId(id);
    direction == "rtl"
      ? setMessage("هل تريد حذف هذا العنصر؟")
      : setMessage("Are you sure you want to delete this item");
    setOpen(true);
  };

  const handleChangeStatus = id => {
    setId(id);
    direction == "rtl"
      ? setChangeStatusMessage("تغيير حالة الطلب")
      : setChangeStatusMessage("Change order status");
    setOpenStatus(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseStatus = () => {
    setOpenStatus(false);
  };
  const handleAction = () => {
    OrdersService.deleteOrder(id)
      .then(resp => {
        toast.success("Order deleted successfully");
        getOrders();
        setOpen(false);
      })
      .catch(error => {
        toast.error(error?.response?.data?.message);
      });
  };
  const DamageDialog = props => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{message}</DialogTitle>
        <DialogActions>
          <MDButton color='primary' onClick={handleAction} autoFocus>
            {direction == "ltr" ? "Yes" : "نعم"}
          </MDButton>
          <MDButton color='red' onClick={handleClose}>
            {direction == "ltr" ? "No" : "لا"}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const showOrder = d => {
    setOrder(d);
    setShowDetails(true);
  };

  const DetailsDialog = ({ showDetails, order, pColumns, handleCloseDetails }) => {
    return (
      <Dialog
        open={showDetails}
        onClose={handleCloseDetails}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          <div class='row' style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",
                display: "flex",
                alignItems: "center",
                width: "25%",
                borderRadius: "20px",
                padding: "1em",
              }}
            >
              Order for: {order?.user?.name} <br />
            </div>
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",
                display: "flex",
                alignItems: "center",
                width: "25%",
                borderRadius: "20px",
                padding: "1em",
              }}
            >
              Phone: {order?.user?.phone} <br />
            </div>
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                padding: "1em",
                width: "25%",
              }}
            >
              Date: {order?.date} <br />
            </div>
          </div>
          <div
            class='row'
            style={{ marginTop: "1em", display: "flex", justifyContent: "space-between" }}
          >
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",
                display: "flex",
                alignItems: "center",
                color: order?.order_status?.color,
                borderRadius: "20px",
                padding: "1em",
                width: "25%",
              }}
            >
              Status: {order?.order_status?.name} <br />
            </div>
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                padding: "1em",
                width: "25%",
              }}
            >
              Address: {order?.address} <br />
            </div>
            <div
              class='col-md-4'
              style={{
                border: "1px solid orange",

                borderRadius: "20px",
                padding: "1em",
                width: "25%",
              }}
            >
              Total: {order?.total}
            </div>
          </div>
          {order != null && (
            <DataTable
              type='orders'
              table={{ columns: pColumns, rows: order.items }}
              isSorted={true}
              entriesPerPage={true}
              showTotalEntries={true}
              noEndBorder
              canSearch={true}
            />
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color='primary' onClick={handleCloseDetails} autoFocus>
            {direction == "ltr" ? "Close" : "إغلاق"}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleActionStatus = () => {
    let st = ORDER_STATUS.filter(e => e.status == status)[0];
    console.log(st);
    let data = new FormData();
    Object.keys(st).forEach(e => {
      data.append(e, st[e]);
      console.log(e, st[e]);
    });
    OrdersService.changeStatus(id, data)
      .then(resp => {
        console.log(resp);
        getOrders();
        toast.success(resp.message);
      })
      .catch(error => {
        toast.error("An error occured");
      });
  };
  const ChangeStatus = props => {
    return (
      <Dialog
        open={openStatus}
        onClose={handleCloseStatus}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{changeStatusMessage}</DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={10}>
            <TextField
              style={{ height: "3em" }}
              label={direction == "ltr" ? "Status" : "الحالة"}
              name='status'
              variant='outlined'
              margin='dense'
              value={status.status}
              fullWidth
              select
              onChange={event => setStatus(event.target.value)}
              onBlur={props.handleBlur}
              required
            >
              {ORDER_STATUS != null &&
                ORDER_STATUS.map(cat => (
                  <MenuItem key={cat.status} value={cat.status}>
                    {cat.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton color='primary' onClick={handleActionStatus} autoFocus>
            {direction == "ltr" ? "Yes" : "نعم"}
          </MDButton>
          <MDButton color='red' onClick={handleClose}>
            {direction == "ltr" ? "No" : "لا"}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };
  //
  const ChangeStatusDialog = props => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{changeStatusMessage}</DialogTitle>
        <DialogActions>
          <MDButton color='primary' onClick={handleAction} autoFocus>
            {direction == "ltr" ? "Yes" : "نعم"}
          </MDButton>
          <MDButton color='red' onClick={handleClose}>
            {direction == "ltr" ? "No" : "لا"}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };
  // custom pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [search, setSearch] = useState();
  const onSearchChange = useAsyncDebounce(value => {
    setSearch(value);
    if (!!value) {
      let _searched = orders.filter(
        e =>
          e.en_name.toLowerCase().includes(value) ||
          e.ar_name.toLowerCase().includes(value) ||
          e.brand.toLowerCase().includes(value) ||
          e.unit_price.toString().toLowerCase().includes(value) ||
          e.entity.toString().toLowerCase().includes(value) ||
          e.selling_price.toString().toLowerCase().includes(value)
      );
      setFiltered(_searched);
    } else setFiltered(orders);
  }, 100);
  //

  return (
    <DashboardLayout>
      {!show && (
        <>
          {" "}
          <DashboardNavbar name={direction == "rtl" ? "الطلبات" : "Orders"} />
          <MDButton onClick={() => generateExcel()} color='primary'>
            {direction == "rtl" ? "تصدير" : "Export"}
          </MDButton>
          <MDInput
            style={{ marginTop: "1em" }}
            placeholder={direction == "rtl" ? "بحث" : "Search"}
            value={search}
            size='small'
            fullWidth
            onChange={({ currentTarget }) => {
              onSearchChange(currentTarget.value);
            }}
          />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant='gradient'
                    bgColor='info'
                    borderRadius='lg'
                    coloredShadow='info'
                  >
                    <MDTypography variant='h6' color='white'>
                      {direction == "rtl" ? "جدول الطلبات" : "Orders Table"}
                    </MDTypography>
                  </MDBox>
                  {rows != null && rows.length > 0 && (
                    <DataTable
                      type='orders'
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      canSearch={true}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>{" "}
        </>
      )}
      <DamageDialog />
      <ChangeStatus />
      <DetailsDialog
        order={_order}
        showDetails={showDetails}
        handleCloseDetails={handleCloseDetails}
        pColumns={pColumns}
      />
    </DashboardLayout>
  );
}

export default Orders;
